<template>
  <div class="combo">
    <div class="combo-info">
      <eden-image :image-url="image" :image-size="60" />
      <div class="combo-info__title">
        <p>({{ index > -1 ? index : "" }}) {{ name }}</p>
        <span>{{ calorie }} calories</span>
        <span>Food tags: {{ food_tags }}</span>
      </div>
      <div class="combo-info__actions">
        <span @click="$emit('edit')"><i class="eden-icon-edit" /> </span>
        <span @click="$emit('delete')"><i class="eden-icon-delete" /> </span>
      </div>
    </div>
    <div class="combo-collapse">
      <el-collapse accordion @input="expand">
        <el-collapse-item name="1" class="remove-icon">
          <template slot="title">
            <p class="combo-collapse--title">
              {{ !collapse ? "Expand" : "Collapse" }} combo ingredients
              <i :class="['el-icon-arrow-down', { collapse: collapse }]" />
            </p>
          </template>
          <div class="combo-collapse--content">
            <div class="label">
              <template v-if="mealItemIngredients?.length">
                <p>
                  Select/deselect ingredients. Selected ingredients will be
                  visible to customers on Garden.
                </p>
                <p>
                  <span @click="updateSelection('select')">Select all</span> -
                  <span @click="updateSelection('deselect')">Deselect all</span>
                </p>
              </template>
              <p v-else>No ingredients on main item</p>
            </div>
            <div class="selections">
              <eden-checkbox
                :options="mealItemIngredients"
                :property="'name'"
                :selected.sync="comboIngredients"
              />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "MealItemCombo",
  props: {
    mealItemIngredients: {
      type: Array,
      default() {
        return [];
      },
    },
    combo: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      comboIngredients: [],
      collapse: "",
    };
  },
  computed: {
    image() {
      return this.combo.image;
    },
    name() {
      return this.combo.name ?? "Name Not Set";
    },
    calorie() {
      return this.combo.calorie;
    },
    food_tags() {
      return this.combo.food_tags?.length
        ? this.combo.food_tags?.toString()
        : "";
    },
    setCombo: {
      get() {
        return this.combo;
      },
      set(value) {
        this.$emit("update:combo", value);
      },
    },
  },
  watch: {
    comboIngredients() {
      this.setCombo.ingredients = this.comboIngredients;
    },
  },
  created() {
    this.comboIngredients = this.combo.invisible_ingredients_id || [];
  },
  methods: {
    expand(e) {
      this.collapse = e === "1";
    },
    updateSelection(action) {
      this.comboIngredients =
        action === "select"
          ? this.mealItemIngredients.map((ingredient) => ingredient.name)
          : [];
    },
  },
};
</script>

<style scoped lang="scss">
.combo {
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  padding: 12px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-info {
    display: flex;
    align-items: flex-start;
    position: relative;

    &__title {
      margin-left: 12px;
      width: 60%;

      p {
        color: var(--eden-grey-primary);
        font-size: 0.825rem;
        font-weight: 500;
      }

      span {
        display: block;
        color: var(--eden-grey-tertiary);
        font-size: 0.75rem;
        margin-top: 5px;
      }
    }

    &__actions {
      position: absolute;
      bottom: 12px;
      right: 12px;

      span {
        padding: 7px;
        cursor: pointer;

        i {
          font-size: 1.125rem;
          color: var(--eden-grey-primary);
        }
      }
    }
  }

  &-collapse {
    &--title {
      color: var(--eden-green-primary) !important;
      font-size: 0.825rem;
      font-weight: 500;

      i {
        margin-left: 12px;
        font-size: 1rem;
        font-weight: 600;
        transform: rotateX(0);
        transition: transform 0.3s ease-out;

        &.collapse {
          transform: rotateX(180deg);
          transition: transform 0.3s ease-in;
        }
      }
    }

    &--content {
      margin-top: 10px;

      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        p {
          font-size: 0.825rem;
          color: var(--eden-grey-tertiary);

          span {
            cursor: pointer;
            color: var(--eden-green-primary);
          }
        }
      }
    }
  }
}

.el-collapse-item {
  padding: 0;
  border: none;
  margin-top: 12px;
}
</style>
