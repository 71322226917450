<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24" class="is-flex is-justify-center">
          <eden-image-upload
            :image-size="60"
            :image-url.sync="form.image"
            :image-text="'meal image'"
            :image-folder="'services/meal'"
          />
        </el-col>
      </el-row>
      <el-row v-for="(side, i) in mealItem.side_types" :key="i" type="flex">
        <el-col :md="24">
          <el-form-item
            :label="`${formatText(side.split('_').join(' '))}`"
            :prop="side"
            :rules="validateField()"
          >
            <el-select
              v-model="form[side]"
              filterable
              clearable
              :placeholder="`Select this combo's ${side.split('_')[0]}`"
              @change="setComboName"
            >
              <el-option
                v-for="(sideItem, index) in getSides(side)"
                :key="index"
                :label="sideItem.name"
                :value="sideItem.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Calorie count">
            <el-input v-model.number="form.calorie" v-decimal />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :md="24">
          <el-form-item label="Food tags">
            <el-select
              v-model="form.tags_id"
              multiple
              filterable
              allow-create
              placeholder="Add a tag"
            >
              <el-option
                v-for="(tag, index) in food_tags"
                :key="index"
                :label="tag.name"
                :value="tag.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :md="24">
          <el-form-item
            label="Chef's notes"
            prop="chef_note"
            :rules="validateField()"
          >
            <el-input v-model="form.chef_note" type="textarea" rows="4" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" @click="update">{{
        action === "add" ? "Add" : "Save changes"
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "MealItemComboForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    combo: {
      type: Object,
      default() {
        return {};
      },
    },
    mealItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showTitle: true,
      form: {
        name: "",
        calorie: "",
        image: "",
        ingredients: [],
        food_tags: [],
        tags_id: [],
        chef_note: "",
      },
    };
  },
  computed: {
    title() {
      return this.action === "add" ? "Add a combination" : "Edit combination";
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    mealItems() {
      const items = this.parseData(
        this.$store.getters.meal_item_list.filter(
          (item) => item.meal_type === "side",
        ),
      );
      return this.sortArray({ data: items, property: "name" });
    },
    mainSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "main_sides";
      });
    },
    proteinSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "protein_sides";
      });
    },
    otherSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "other_sides";
      });
    },
    sauceSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "sauce_sides";
      });
    },
    food_tags() {
      return this.$store.getters.food_tags;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.showTitle = false;

        if (this.action === "add") {
          this.form = {
            name: "",
            calorie: "",
            image: "",
            ingredients: [],
            // food_tags: this.mealItem.food_tags,
            tags_id: this.mealItem.tags_id,
            chef_note: this.mealItem.chef_note,
          };
          this.mealItem.side_types.forEach((side) => {
            this.form = {
              ...this.form,
              [side]: null,
            };
          });
        } else {
          this.form = {
            ...this.combo,
            image: this.combo.image,
            food_tags: this.combo.food_tags?.length
              ? this.combo.food_tags
              : this.mealItem.food_tags,
            chef_note: this.combo.chef_note
              ? this.combo.chef_note
              : this.mealItem.chef_note,
          };
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.showTitle = true;
    },
    getSides(side) {
      switch (side) {
        case "main_sides":
          return this.mainSides;
        case "protein_sides":
          return this.proteinSides;
        case "other_sides":
          return this.otherSides;
        case "sauce_sides":
          return this.sauceSides;
        default:
          break;
      }
    },
    setComboName() {
      const { main_sides, protein_sides, other_sides, sauce_sides } = this.form;

      const main_side = this.mainSides.filter((item) => item.id === main_sides);
      const protein_side = this.proteinSides.filter(
        (item) => item.id === protein_sides,
      );
      const other_side = this.otherSides.filter(
        (item) => item.id === other_sides,
      );
      const sauce_side = this.sauceSides.filter(
        (item) => item.id === sauce_sides,
      );

      const combo = this.concatenateItems(
        main_side[0]?.name,
        protein_side[0]?.name,
        other_side[0]?.name,
        sauce_side[0]?.name,
      );

      this.form.name = `${this.mealItem.name}, ${combo}`;
    },
    update() {
      this.$emit("update", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  max-height: 320px;
  overflow-y: scroll;
}
</style>
