<template>
  <div>
    <eden-form-section
      :title="`Combinations (${setCombos.length})`"
      subtitle="Create the possible combos that this meal will come in."
    >
      <template slot="form-fields">
        <el-row v-if="setCombos.length" type="flex">
          <el-col :span="24" class="is-flex is-justify-end">
            <div class="section-add" @click="addCombo">
              <span><i class="el-icon-plus" /></span>
              <p>Add a combo</p>
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-form-section>
    <el-row v-if="!setCombos.length" type="flex">
      <el-col :span="24" class="combo-empty">
        <div class="section-add" @click="addCombo">
          <span><i class="el-icon-plus" /></span>
          <p>Add a combo</p>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="setCombos.length" type="flex" class="mb-4">
      <el-col :md="8">
        <el-input
          v-model="query"
          type="text"
          :prefix-icon="'el-icon-search'"
          placeholder="Search for a combination"
        ></el-input>
      </el-col>
      <el-col :md="16" class="is-flex is-justify-end">
        <el-dropdown @command="setSort">
          <el-button type="plain">
            Sort by: {{ sorts[sort] }}
            <i class="eden-icon-arrow-down"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key, i) in sorts"
              :key="i"
              :class="{ selected: key === sort }"
              :command="key"
              >{{ value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <meal-item-combo
          class="fadeIn"
          v-for="(combo, index) in sortedCombos"
          :key="index"
          :meal-item-ingredients="mealItem.ingredients"
          :combo.sync="combo"
          :index="setCombos.findIndex((item) => item.id == combo.id)"
          @edit="editCombo(combo)"
          @delete="deleteCombo(combo.id)"
        />
      </el-col>
    </el-row>
    <meal-item-combo-form
      :action="combo.action"
      :show.sync="combo.visibility"
      :combo="combo.data"
      :meal-item="mealItem"
      @update="updateCombo"
    />
  </div>
</template>

<script>
import MealItemCombo from "@/components/Services/Meal/MealItems/MealItemCombos/MealItemCombo";
import MealItemComboForm from "@/components/Services/Meal/MealItems/MealItemCombos/MealItemComboForm";

export default {
  name: "MealItemCombos",
  components: {
    MealItemCombo,
    MealItemComboForm,
  },
  props: {
    mealItem: {
      type: Object,
      default() {
        return {};
      },
    },
    combos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      query: "",
      sort: "newest",
      sorts: {
        "newest": "Newest first",
        "oldest": "Oldest first",
        "a-z": "A-Z",
        "z-a": "Z-A",
      },
      combo: {
        action: "add",
        visibility: false,
        data: {},
      },
    };
  },
  computed: {
    setCombos: {
      get() {
        return this.combos;
      },
      set(value) {
        this.$emit("update:combos", value);
      },
    },
    sortedCombos() {
      let query = this.query;
      let sort = this.sort;
      let combos = this.setCombos;
      if (query) {
        combos = combos.filter((combo) =>
          combo.name.toLowerCase().includes(query.toLowerCase()),
        );
      }

      switch (sort) {
        case "newest":
          return combos;
        case "oldest":
          return combos.reverse();
        case "a-z":
          return this.sortArray({
            data: combos,
            property: "name",
            direction: "asc",
          });
        case "z-a":
          return this.sortArray({
            data: combos,
            property: "name",
            direction: "dsc",
          });
        default:
          return combos;
      }
    },
    mealItems() {
      const items = this.parseData(this.$store.getters.meal_item_list);
      return this.sortArray({ data: items, property: "name" });
    },
    mainSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "main_sides";
      });
    },
    proteinSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "protein_sides";
      });
    },
    otherSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "other_sides";
      });
    },
    sauceSides() {
      return this.mealItems.filter((item) => {
        return item.side_category === "sauce_sides";
      });
    },
  },
  created() {
    this.setCombos = this.setCombos.map((combo) => {
      return {
        ...combo,
        name: this.setComboName(combo),
      };
    });
  },
  methods: {
    addCombo() {
      this.combo.action = "add";
      this.combo.visibility = true;
    },
    editCombo(combo) {
      const index = this.setCombos.findIndex((item) => item.id == combo.id);

      this.combo.action = "edit";
      this.combo.index = index;
      this.combo.data = { ...combo };
      this.combo.visibility = true;
    },
    validCombo(name) {
      const { name: selectedCombo = "" } = this.combo.data;
      const combos = this.setCombos.filter((combo) => {
        return (
          combo.name.toLowerCase() === name.toLowerCase() &&
          combo.name.toLowerCase() !== selectedCombo.toLowerCase()
        );
      });

      if (combos.length) {
        this.$message.error({
          message: "Combo already exist!",
          duration: 4000,
        });
        return false;
      } else {
        return true;
      }
    },
    updateCombo(combo) {
      const { action, index } = this.combo;
      if (!this.validCombo(combo.name)) {
        return;
      }

      if (action === "add") {
        this.setCombos.unshift(combo);
      } else {
        this.setCombos.splice(index, 1, { ...combo });
      }

      this.combo.visibility = false;
    },
    deleteCombo(id) {
      const index = this.setCombos.findIndex((item) => item.id == id);

      if (this.setCombos.length > 1) {
        this.setCombos.splice(index, 1);
      } else {
        this.$message.info("You need to have at least 1 combo added.");
      }
    },
    setQuery(query) {
      this.query = query;
    },
    setSort(sort) {
      this.sort = sort;
    },
    setComboName(combo) {
      const { main_sides, protein_sides, other_sides, sauce_sides } = combo;

      const main_side = this.mainSides.filter((item) => item.id === main_sides);
      const protein_side = this.proteinSides.filter(
        (item) => item.id === protein_sides,
      );
      const other_side = this.otherSides.filter(
        (item) => item.id === other_sides,
      );
      const sauce_side = this.sauceSides.filter(
        (item) => item.id === sauce_sides,
      );

      const sides = this.concatenateItems(
        main_side[0]?.name,
        protein_side[0]?.name,
        other_side[0]?.name,
        sauce_side[0]?.name,
      );

      return `${this.mealItem.name}, ${sides}`;
    },
  },
};
</script>

<style scoped lang="scss">
.combo-empty {
  width: 50%;
  margin: 0 auto;
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  .combo-add {
    margin-top: 0;
  }
}
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>
