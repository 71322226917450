<template>
  <div v-loading="prefilling" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <template v-if="step === 1">
        <eden-form-section>
          <template slot="form-fields">
            <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
              <el-col :md="24" class="is-flex is-flex-column align-center">
                <eden-image-upload
                  :image-url.sync="form.image"
                  :image-text="'meal image'"
                  :image-folder="'services/meal'"
                />
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          title="Meal Item Details"
          subtitle="Enter basic information about meal item."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Item name"
                  prop="service_type_name"
                  :rules="validateMealName()"
                >
                  <el-input
                    type="text"
                    v-model="form.service_type_name"
                    placeholder=""
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item
                  label="Meal category"
                  prop="class_category"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.class_category"
                    multiple
                    filterable
                    placeholder="Select this meal's category"
                  >
                    <el-option
                      v-for="(category, index) in mealClassCategories"
                      :key="index"
                      :label="category.category"
                      :value="category.category.toLowerCase()"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item
                  label="Meal type"
                  prop="meal_type"
                  :rules="validateField()"
                >
                  <el-radio-group v-model="form.meal_type">
                    <el-radio label="main">Main item</el-radio>
                    <el-radio label="side">Side item</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="form.meal_type === 'side'" type="flex" class="fadeIn">
              <el-col :md="24">
                <el-form-item
                  label="Side category"
                  prop="side_category"
                  :rules="validateField()"
                >
                  <el-radio-group v-model="form.side_category">
                    <el-radio label="main_sides">Main side</el-radio>
                    <el-radio label="protein_sides">Protein</el-radio>
                    <el-radio label="other_sides">Other side</el-radio>
                    <el-radio label="sauce_sides">Sauce</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          v-if="form.meal_type === 'main'"
          class="fadeIn"
          title="Sides Information"
          subtitle="Does this meal item comes with sides? If yes, select all the possible types of sides that can go with this meal item."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :span="24">
                <div class="is-flex mb-2">
                  <span class="font-base mr-2"
                    >This item comes with a side</span
                  >
                  <el-switch
                    v-model="form.main_with_sides"
                    :active-value="true"
                    :inactive-value="false"
                    @change="resetSideTypes"
                  >
                  </el-switch>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="form.main_with_sides" type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Side types"
                  prop="side_types"
                  :rules="validateField('Side type')"
                >
                  <el-checkbox-group v-model="form.side_types">
                    <el-checkbox label="main_sides">Main side</el-checkbox>
                    <el-checkbox label="protein_sides">Protein</el-checkbox>
                    <el-checkbox label="other_sides">Other sides</el-checkbox>
                    <el-checkbox label="sauce_sides">Sauce</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          v-if="form.meal_type !== ''"
          class="fadeIn"
          :title="`Ingredients (${form.ingredients.length})`"
          subtitle="Enter the ingredients that make up this meal item."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :span="24" v-if="action === 'add'">
                <template v-for="(ingredient, index) in form.ingredients">
                  <el-row
                    type="flex"
                    :gutter="15"
                    :key="index"
                    class="ingredient fadeIn"
                  >
                    <el-col :sm="12" :md="12">
                      <el-form-item
                        label="Ingredient"
                        :prop="'ingredients.' + index + '.id'"
                        :rules="validateField()"
                      >
                        <el-select
                          v-model="ingredient.id"
                          filterable
                          placeholder="Select ingredient"
                          @change="setIngredientProperties($event, index)"
                        >
                          <el-option
                            v-for="ingredient in mealIngredients"
                            :key="ingredient.id"
                            :label="formatText(ingredient.name)"
                            :value="ingredient.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="12">
                      <div class="is-flex is-align-center">
                        <el-form-item
                          label="Quantity"
                          :prop="'ingredients.' + index + '.qty'"
                          :rules="validateField()"
                        >
                          <el-input v-model.number="ingredient.qty" v-number>
                            <el-select
                              v-model="ingredient.measurement"
                              placeholder=""
                              slot="append"
                            >
                              <el-option
                                v-for="unit in mealIngredientsMeasurements"
                                :key="unit.id"
                                :label="formatText(unit.name)"
                                :value="unit.name.toLowerCase()"
                              />
                            </el-select>
                          </el-input>
                        </el-form-item>
                        <span
                          class="ingredient-remove"
                          @click="removeIngredient(index)"
                        >
                          <i class="eden-icon-delete" />
                        </span>
                      </div>
                    </el-col>
                  </el-row>
                </template>
                <el-row type="flex">
                  <el-col :span="24" class="is-flex is-justify-center">
                    <div class="ingredient-add" @click="addIngredient">
                      <span><i class="el-icon-plus" /></span>
                      <p>Add an ingredient</p>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="24" v-else>
                <template v-for="(ingredient, index) in form.ingredients">
                  <el-row
                    type="flex"
                    :gutter="15"
                    :key="index"
                    class="ingredient fadeIn"
                  >
                    <el-col :sm="12" :md="12">
                      <el-form-item
                        label="Ingredient"
                        :prop="'ingredients.' + index + '.id'"
                        :rules="validateField()"
                      >
                        <el-select
                          v-model="ingredient.ingredient_id"
                          filterable
                          placeholder="Select ingredient"
                          @change="setIngredientProperties($event, index)"
                        >
                          <el-option
                            v-for="ingredient in mealIngredients"
                            :key="ingredient.id"
                            :label="formatText(ingredient.name)"
                            :value="ingredient.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :md="12">
                      <div class="is-flex is-align-center">
                        <el-form-item
                          label="Quantity"
                          :prop="'ingredients.' + index + '.qty'"
                          :rules="validateField()"
                        >
                          <el-input v-model.number="ingredient.qty" v-number>
                            <el-select
                              v-model="ingredient.measurement"
                              placeholder=""
                              slot="append"
                            >
                              <el-option
                                v-for="unit in mealIngredientsMeasurements"
                                :key="unit.id"
                                :label="formatText(unit.name)"
                                :value="unit.name.toLowerCase()"
                              />
                            </el-select>
                          </el-input>
                        </el-form-item>
                        <span
                          class="ingredient-remove"
                          @click="removeIngredient(index)"
                        >
                          <i class="eden-icon-delete" />
                        </span>
                      </div>
                    </el-col>
                  </el-row>
                </template>
                <el-row type="flex">
                  <el-col :span="24" class="is-flex is-justify-center">
                    <div class="ingredient-add" @click="addIngredient">
                      <span><i class="el-icon-plus" /></span>
                      <p>Add an ingredient</p>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          v-if="form.meal_type !== '' && !form.main_with_sides"
          class="fadeIn"
          title="Calorie Count Tags"
          subtitle="Enter the calorie count for this meal item."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item label="Calorie count">
                  <el-input v-model.number="form.calorie" v-decimal />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          v-if="form.meal_type !== ''"
          class="fadeIn"
          title="Food Tags"
          subtitle="Add any necessary food tags for this meal item eg. Gluten free, contains soy, contains nuts, contains shellfish."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item label="Food tags">
                  <el-select
                    v-model="form.food_tags"
                    multiple
                    filterable
                    :loading="isCreatingFoodTag"
                    allow-create
                    placeholder="Add a tag"
                    @change="handleFoodTag"
                  >
                    <el-option
                      v-for="(tag, index) in mealFoodTags"
                      :key="index"
                      :label="tag.name"
                      :value="tag.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          v-if="form.meal_type === 'main'"
          class="fadeIn"
          title="Chef's Notes"
          subtitle="Add any necessary notes from the chef."
        >
          <template slot="form-fields">
            <el-row type="flex">
              <el-col :md="24">
                <el-form-item label="Chef's notes">
                  <el-input v-model="form.chef_note" type="textarea" rows="4" />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section title="" subtitle="">
          <template slot="form-fields">
            <div class="eden-page-form__actions">
              <el-button plain @click="cancel">Cancel</el-button>
              <el-button
                v-if="form.main_with_sides"
                type="primary"
                @click="next"
                >Next
              </el-button>
              <template v-else>
                <el-button
                  v-if="action === 'add'"
                  type="primary"
                  :loading="adding"
                  @click="add"
                  >Add meal item
                </el-button>
                <el-button
                  v-else
                  type="primary"
                  :loading="updating"
                  @click="update"
                  >Save changes
                </el-button>
              </template>
            </div>
          </template>
        </eden-form-section>
      </template>
      <template v-else-if="step === 2">
        <meal-item-combos
          :meal-item="mealItem"
          :food-tags="mealFoodTags"
          :combos.sync="form.combos"
        />
        <div class="form-actions">
          <el-button plain @click="step = 1">Back</el-button>
          <el-button
            v-if="action === 'add'"
            :loading="adding"
            type="primary"
            @click="add"
            >Add meal item
          </el-button>
          <el-button
            v-else
            type="primary"
            :disabled="!form.combos?.length"
            :loading="updating"
            @click="update"
            >Save changes
          </el-button>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import tags from "@/requests/services/meal/food-tags";

import MealItemCombos from "@/components/Services/Meal/MealItems/MealItemCombos/MealItemCombos";

import * as actions from "@/store/action-types";

import categories from "@/requests/services/meal/categories";
import ingredients from "@/requests/services/meal/ingredients";
import foodTags from "@/requests/services/meal/food-tags";
// import service from "@/requests/services/service";
import mealItem from "@/requests/services/meal";

export default {
  name: "MealItemForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    MealItemCombos,
  },
  data() {
    return {
      prefilling: false,
      step: 1,
      form: {
        allergy: {},
        service: "Meal",
        service_type_category: "single",
        image: null,
        combo_image: "",
        service_type_name: "",
        meal_type: "",
        class_category: null,
        side_category: "",
        main_with_sides: false,
        side_types: [],
        ingredients: [],
        calorie: "",
        food_tags: [],
        chef_note: "",
        preset_combos: [],
        combos: [],
      },
      mealClassCategories: [],
      mealIngredients: {},
      mealIngredientsMeasurements: [],
      mealFoodTags: [],
      adding: false,
      updating: false,
      isCreatingFoodTag: false,
    };
  },
  computed: {
    mealItem() {
      return {
        name: this.form.service_type_name,
        side_types: this.form.side_types,
        ingredients: this.form.ingredients,
        food_tags: this.form.food_tags,
        chef_note: this.form.chef_note,
        ingredientOptions: this.mealIngredients,
      };
    },
    mealItems() {
      return this.$store.getters.meal_item_list;
    },
  },
  async created() {
    if (this.action === "edit") {
      this.prefilling = true;
    }
    if (this.mealItems?.length === 0) {
      this.prefilling = true;
      await this.$store.dispatch(actions.GET_MEAL_ITEM_LIST);
      this.prefilling = false;
    }
    await this.getMealCategories();
    await this.getMealFoodTags();
    await this.getMealIngredients();
    await this.getMealIngredientsMeasurements();

    if (this.action === "edit") {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.serviceType[key];
      });

      console.log(this.serviceType);
      setTimeout(() => {
        this.prefilling = false;
      }, 1000);
    }
  },
  methods: {
    getMealCategories() {
      categories.list().then((response) => {
        if (response.data.status) {
          this.mealClassCategories = this.sortArray({
            data: response.data.data,
            property: "category",
          });
        }
      });
    },
    getMealFoodTags() {
      foodTags.list().then((response) => {
        if (response.data.status) {
          this.mealFoodTags = this.sortArray({
            data: response.data.data,
            property: "name",
          });
        }
      });
    },
    getMealIngredients() {
      ingredients.list().then((response) => {
        if (response.data.status) {
          this.mealIngredients = this.sortArray({
            data: response.data.data,
            property: "name",
          });
        }
      });
    },
    getMealIngredientsMeasurements() {
      ingredients.measurementList().then((response) => {
        if (response.data.status) {
          this.mealIngredientsMeasurements = this.sortArray({
            data: response.data.data,
            property: "name",
          });
        }
      });
    },
    resetSideTypes() {
      if (!this.form.main_with_sides) {
        this.form.side_types = [];
      }
    },
    addIngredient() {
      this.form.ingredients.push({
        id: null,
        name: "",
        qty: null,
        measurement: "",
        visibility: false,
      });
    },
    setIngredientProperties(e, index) {
      const ingredient = this.mealIngredients.filter((i) => i.id === e);

      if (ingredient.length) {
        this.form.ingredients[index].name = ingredient[0].name;
        this.form.ingredients[index].measurement = ingredient[0].measurement;
      }
    },
    removeIngredient(index) {
      console.log(this.form);
      const itemToDelete = this.form.ingredients[index];
      this.form.ingredients.splice(index, 1);

      if (itemToDelete.ingredient_id) {
        mealItem
          .deleteMealItemIngredient({ meal_ingredient_id: itemToDelete.id })
          .then((response) => {
            this.$message.success(response.data.message);
          })
          .catch((error) => {
            this.form.ingredients.splice(index, 0, itemToDelete);
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      }
    },
    cancel() {
      this.$router.push({ name: "services.service", service: "meal" });
    },
    createFoodTag(item) {
      this.isCreatingFoodTag = true;
      const payload = {
        name: item,
      };
      tags
        .add(payload)
        .then(async (response) => {
          if (response.data.status) {
            console.log(response.data);
            await this.getMealFoodTags();
            setTimeout(() => {
              this.form.food_tags.splice(
                this.form.food_tags.length - 1,
                1,
                response.data.data?.id,
              );
            }, 100);

            this.isCreatingFoodTag = false;

            this.$message.success(response.data.message);
          } else {
            this.isCreatingFoodTag = false;
          }
        })
        .catch((error) => {
          this.isCreatingFoodTag = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    handleFoodTag(val) {
      console.log(this.form.food_tags);
      const newItem = val.length > 0 ? val[val.length - 1] : null;

      if (newItem && typeof newItem === "string") {
        const foodTagExists = this.mealFoodTags.find(
          (item) => item.name?.toLowerCase() === newItem.toLowerCase,
        );
        if (!foodTagExists) {
          this.createFoodTag(newItem);
        }
      }
    },
    next() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.step = this.step === 1 ? 2 : 1;
      });
    },
    payload() {
      let payload = this.parseData(this.form);
      payload.class_category = payload.class_category?.toString();
      payload.food_tags = payload.food_tags?.toString();

      if (payload.meal_type === "side") {
        payload.max_no_of_sides = null;
        payload.side_types = null;
        payload.main_sides = null;
        payload.protein_sides = null;
        payload.sauce_sides = null;
        payload.other_sides = null;
        payload.preset_combos = null;
      } else {
        payload.max_no_of_sides = payload.side_types?.length;
        payload.side_category = null;
        payload.side_types = payload.side_types
          ? payload.side_types.toString()
          : null;

        const formattedPresetCombos = payload.combos.map((combo) => {
          delete combo.name;
          return { ...combo };
        });
        payload.combos = payload.combos.length
          ? JSON.stringify(formattedPresetCombos)
          : null;
        payload.combo_image = payload.image;
      }

      const body = {
        service_name: this.form.service,
        name: this.form.service_type_name,
        category: this.form.service_type_category,
        meal_type: this.form.meal_type,
        meal_category: this.form.class_category.join(", "),
        main_with_sides: this.form.main_with_sides,
        side_types: this.form.side_types,
        side_category: this.form.side_category,
        image: this.form.image,
        image_url: this.form.image,
        chef_note: this.form.chef_note,
        calorie: this.form.calorie,
        tags_id: this.form.food_tags,
        ingredients: this.form.ingredients?.map((item) =>
          this.action === "add"
            ? {
                ingredient_id: item.id,
                qty: item.qty,
                measurement: item.measurement,
              }
            : {
                id: item.id,
                ingredient_id: item.ingredient_id,
                qty: item.qty,
                measurement: item.measurement,
              },
        ),
        meal_combos: this.form.combos.map((item) => {
          return {
            combo_id: this.action === "add" ? undefined : item.id,
            main_sides: item.main_sides,
            protein_sides: item.protein_sides,
            sauce_sides: item.sauce_sides,
            other_sides: item.other_sides,
            chef_note: item.chef_note,
            calorie: item.calorie,
            image: item.image,
            tags_id: item.tags_id || [],
            invisible_ingredient_ids: item.ingredients?.map((item) => {
              const actual = this.mealIngredients.find(
                (val) => val.name === item,
              );
              return actual?.id;
            }),
          };
        }),
      };

      if (this.action === "edit") {
        body.meal_id = this.serviceTypeId;
        const deletedCombos =
          this.serviceType?.combos
            ?.filter(
              (item) => !this.form.combos.map((fc) => fc.id).includes(item.id),
            )
            ?.map((item) => item.id) || [];

        body.deleted_combos = deletedCombos;
      }

      return body;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        const payload = this.payload();
        console.log(payload, "Payloaddd")
        mealItem
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              this.adding = false;
              this.$message.success(response.data.message);
              this.$router.push({
                name: "services.service",
                params: { service: "meal" },
              });
            } else {
              this.$message.error(response.data.message);
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.payload();
        console.log(payload, "Payloaddd")
        mealItem
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$router.push({
                name: "services.service",
                params: { service: "meal" },
              });
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ingredient-add,
.combo-add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--eden-green-senary);
    border-radius: 10px;
    margin-right: 5px;

    i {
      color: var(--eden-green-primary);
      font-size: 0.75rem;
      font-weight: 60;
    }
  }

  p {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }
}

.ingredient-remove {
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.combo-empty {
  width: 50%;
  margin: 0 auto;
  border: 1px solid var(--eden-grey-quinary);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  .combo-add {
    margin-top: 0;
  }
}
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
</style>
